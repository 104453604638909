const __o365_sfc__ = {};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overlay"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", {
    class: "overlay__inner"
  }, [_createElementVNode("div", {
    class: "overlay__content"
  }, [_createElementVNode("span", {
    class: "spinner"
  })])], -1 /* HOISTED */)]));
}
    __o365_sfc__.name = 'Overlay';
    __o365_sfc__.__file = 'Overlay';
    __o365_sfc__.render = render;
    
    export default __o365_sfc__;